$(document).ready(function(){

	//Text animations
	$('.from-top').each(function () {
		$(this).appear(function() {
			$(this).delay(150).animate({opacity:1,top:"0px"},1000);
		});
	});

	$('.from-bottom').each(function () {
		$(this).appear(function() {
			$(this).delay(150).animate({opacity:1,bottom:"0px"},1000);
		});
	});

	$('.from-left').each(function () {
		$(this).appear(function() {
			$(this).delay(150).animate({opacity:1,left:"0px"},1000);
		});
	});

	$('.from-right').each(function () {
		$(this).appear(function() {
			$(this).delay(150).animate({opacity:1,right:"0px"},1000);
		});
	});

	$('.fade-in').each(function () {
		$(this).appear(function() {
			$(this).delay(150).animate({opacity:1,right:"0px"},1000);
		});
	});

	// Footer sliders
  	$('#clientes').slick({
  		centerMode: true,
  		centerPadding: '0',
	  	arrows:true,
	  	autoplay: true,
	  	infinite: true,
	  	speed: 300,
	  	slidesToShow: 4,
	  	slidesToScroll: 1,
	  	responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
		      		centerMode: true,
  					centerPadding: '0',
		        	slidesToShow: 4,
		        	slidesToScroll: 1,
		        	infinite: true
		      	}
		    },
		    {
		      	breakpoint: 992,
		      	settings: {
		      		centerMode: true,
  					centerPadding: '0',
		        	slidesToShow: 3,
		        	slidesToScroll: 1
		      	}
		    },
		    {
		      	breakpoint: 768,
		      	settings: {
		      		centerMode: true,
  					centerPadding: '0',
		        	slidesToShow: 3,
		        	slidesToScroll: 1
		      	}
		    },
		    {
		      	breakpoint: 640,
		      	settings: {
		      		centerMode: true,
  					centerPadding: '0',
			        slidesToShow: 2,
			        slidesToScroll: 1
		      	}
		    },
		    {
		      	breakpoint: 480,
		      	settings: {
		      		centerMode: true,
  					centerPadding: '0',
			        slidesToShow: 1,
			        slidesToScroll: 1
		      	}
		    }
	  	]
	});

  	$('#areas').slick({
  		centerMode: true,
  		centerPadding: '0',
  		arrows:true,
  		infinite: true,
  		autoplay: true,
  		speed: 300,
  		slidesToShow: 4,
  		slidesToScroll: 1,
  		responsive: [
	    	{
	      		breakpoint: 1024,
	      		settings: {
	      			centerMode: true,
	      			centerPadding: '0',
	        		slidesToShow: 4,
	        		slidesToScroll: 1,
	        		infinite: true
	      		}
	    	},
	    {
	      	breakpoint: 992,
	      	settings: {
	      		centerMode: true,
	      		centerPadding: '0',
	        	slidesToShow: 3,
	        	slidesToScroll: 1
	      	}
	    },
	    {
	      	breakpoint: 768,
	      	settings: {
	      		centerMode: true,
	      		centerPadding: '0',
	        	slidesToShow: 3,
	        	slidesToScroll: 1
	      	}
	    },
	    {
	      	breakpoint: 640,
	      	settings: {
	      		centerMode: true,
	      		centerPadding: '0',
	        	slidesToShow: 2,
	        	slidesToScroll: 1
	      	}
	    },
	    {
	      	breakpoint: 480,
	      	settings: {
	      		centerMode: true,
	      		centerPadding: '0',
	        	slidesToShow: 1,
	        	slidesToScroll: 1
	      	}
	    }
  		]
	});

});

$(window).load(function() {

    // Preloader
	$("#loader").delay(50).fadeOut();
	$(".mask").delay(70).fadeOut();
});
